export default function authHeader() {
  let user = JSON.parse(localStorage.getItem('user'));
  if (user && user.accessToken) {
    let header = { 
      'x-access-token': user.accessToken
    }
    return header;
  } else {
    return {};
  }
}