import http from "../http-common";

class UserDataService {
  // create(data) {
  //   return http.post("/user/add_user", data);
  // }
  getAll() {
    return http.get("/admin-users")
  }

  get(id) {
    return http.get("/admin-user/"+id)
  }

  delete(id) {
    return http.post("/admin-user/delete",{
      id: id
    })
  }

  newUser(username, email ,password, roles){
    return http.post("/auth/signup", {

        "username": username,
        "email": email,
        "password": password,
        "roles": [roles]
    })
  }

  updateUser(id,username, email, password, roles) {
    return http.post("/admin-user/update", {
      "id": id,
      "username": username,
      "email": email,
      "password": password,
      "roles": roles
    })
  }
}

export default new UserDataService();
