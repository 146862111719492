import axios from "axios";
import authHeader from './services/AuthHeader';
import store from './store'

let baseURL

if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
  baseURL = "http://localhost:8080/api"
} else if (process.env.NODE_ENV === 'test'){
  baseURL = "https://staging.api.segurosviagem.pt/api"
}else {
  baseURL = "https://api.segurosviagem.pt/api"
}

let headers = authHeader()
headers["Content-type"] = "application/json"

const API = axios.create({
  baseURL: baseURL,
  headers: headers
});

API.interceptors.response.use(null, function (error) {
  if (error.response.status === 401) {
    localStorage.removeItem('user');
    window.location.href = '/login'
  }
  return Promise.reject(error)
})

export default API